import React, { useEffect, useRef, useState } from "react"
import { useWindowSize } from "../hooks/windowsize"
import ContainerStyle from "../styles/modules/layouts.module.scss"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const Layouts = props => {
  const { layouts } = props
  const breakpoint = 992

  const size = useWindowSize()
  const [isDesktop, setDesktop] = useState(size.width > breakpoint)

  const ref = useRef(null)
  const imgContainerRef = useRef(null)
  const imgref = useRef(null)
  const layoutsRef = useRef([])
  const pin = useRef(gsap.timeline({ paused: true, ease: "none" }))
  const tl = useRef(gsap.timeline({ paused: true, ease: "none" }))

  useEffect(() => {
    if (isDesktop) {
      pin.current.to(
        {},
        {
          scrollTrigger: {
            id: "layouts",
            trigger: ref.current,
            pin: imgContainerRef.current,
            start: "top top",
            end: "bottom bottom",
          },
        }
      )

      const img = imgref.current
      layoutsRef.current.forEach((v, i) => {
        tl.current.to(
          {},
          {
            scrollTrigger: {
              id: "layout_" + i,
              trigger: v,
              start: "top center",
              onEnter: () => {
                img.src = v.dataset.img
              },
              onEnterBack: () => {
                img.src = v.dataset.img
              },
            },
          }
        )
      })
    }

    const sticky = pin.current
    const timeline = tl.current

    return () => {
      sticky.kill()
      sticky.getChildren().map(v => {
        return v.scrollTrigger.kill()
      })

      timeline.kill()
      timeline.getChildren().map(v => {
        return v.scrollTrigger.kill()
      })
    }
  }, [size, isDesktop])

  useEffect(() => {
    setDesktop(size.width > breakpoint)
  }, [size.width])

  return (
    <div className="bg--black sp-pt-7 sp-pb-7">
      <div className="container-fluid">
        <div
          ref={ref}
          className={`col-12 col-lg-10 m-auto ${ContainerStyle.layouts}`}
        >
          {layouts.map((v, i) => {
            return (
              <div
                key={i}
                ref={el => {
                  layoutsRef.current[i] = el
                }}
                className={ContainerStyle.layout}
                data-img={`/${v.key}_p.svg`}
              >
                <div className={ContainerStyle.layout__texts}>
                  <div className="text--lg sp-mb-6">{v.title}</div>
                  <div
                    className={`d-flex sp-mb-7 ${ContainerStyle.layout__properties}`}
                  >
                    {v.properties.map((p, j) => (
                      <div className="flex-grow-1" key={j}>
                        <span className="text--sm">{p.title}:</span>
                        <br />
                        <span className="text--md">{p.value}</span>
                      </div>
                    ))}
                  </div>
                  <div className="text--md">{v.content}</div>
                </div>
                {!isDesktop && (
                  <div className={ContainerStyle.topview}>
                    <img src={`/${v.key}_h.svg`} alt="Top view" />
                  </div>
                )}
              </div>
            )
          })}
          {isDesktop && (
            <div>
              <div ref={imgContainerRef} className={ContainerStyle.topview}>
                <img
                  ref={imgref}
                  src={`/${layouts[0].key}_p.svg`}
                  alt="Top view"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Layouts
